import React, { useEffect, useMemo, useState } from "react";
import LogoutIcon from "@mui/icons-material/Logout";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import DateRangeIcon from "@mui/icons-material/DateRange";
import bgImage from "../assets/bgImage.png";
import bullionStats from "../assets/bullioStats.png";
import CeekayLogo from "../assets/CeekayLogo.png";
import {
  Box,
  Divider,
  Fade,
  LinearProgress,
  Modal,
  Typography,
  useMediaQuery,
} from "@mui/material";
import Marquee from "react-fast-marquee";
import "../App.css";
import moment from "moment-timezone";
import io from "socket.io-client";
import Carousel from "react-material-ui-carousel";
import {
  allCommodities,
  allNewses,
  findBanners,
  findUserIp,
  getLiveValueTypeForDisplay,
  getRatio,
  getSocketUrl,
  getSpread,
} from "../sevice/home";
import { loginChecking, logoutTv, tvLoging } from "../sevice/login";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import LimitExceededModal from "./LimitExceededModal";
import SubscriptionExpiringSoon from "./SubscriptionExpiringSoon";
import SubscriptionExpired from "./SubscriptionExpired";
import { firebaseAuth } from "../sevice/config";
import { deviceDetect } from "react-device-detect";
import BlockModal from "./BlockModal";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";

const { makeStyles } = require("@mui/styles");
let socket = Cookies.get("socketUrl")
  ? io(JSON.parse(Cookies.get("socketUrl")))
  : null;

const socket2 = io(process.env.REACT_APP_BACKEND_URL);
const adminId = process.env.REACT_APP_ADMIN_ID;

const useStyles = makeStyles((theme) => ({
  mainBody: {
    height: "100dvh",
    display: "flex",
    flexDirection: "column",
  },
  mainContainer: {
    width: "100%",
    height: "90vh",
    margin: "auto",
    display: "grid",
    gridTemplateColumns: "1.1fr 2fr",
    gridTemplateRows: "1fr",
    gridTemplateAreas: `
        'leftPart rightPart'
      `,
    padding: "1.5rem 2rem 1.5rem 2rem ",
    boxSizing: "border-box",
    columnGap: "2rem",
  },
  leftPart: {
    boxSizing: "border-box",
    gridArea: "leftPart",
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  timeArea: {
    height: "26.5%",
    boxSizing: "border-box",
    borderRadius: "5px",
    display: "flex",
    color: "#fff",
    gap: 4,
    justifyContent: "space-between",
    paddingBottom: "19px",
  },
  banner: {
    height: "40%",
    boxSizing: "border-box",
  },
  bannerImg: {
    width: "100%",
    height: "100%",
    objectFit: "fill",
    overflow: "hidden",
  },
  spotRate: {
    height: "47%",
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    color: "#16341B",
    gap: ".6rem",
    borderRadius: "5px",
    color: "#FFFFFF",
    justifyContent: "end",
  },
  spotRateBox: {
    display: "flex",
    flexDirection: "column",
    gap: "0.3rem",
    alignItems: "center",
    flexBasis: 0,
    flexGrow: 1,
    flexShrink: 1,
  },
  liveRateValue: {
    border: "1px solid #AC7F81",
    color: "#FFFFFF",
    borderRadius: "8px",
    width: "9.3vw",
    height: "7.3vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    transition: "background-color .5s ease",
  },
  liveRateValueSilver: {
    border: "1px solid #AC7F81",
    color: "#FFFFFF",
    borderRadius: "8px",
    width: "9.3vw",
    height: "6.5vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    transition: "background-color .5s ease",
  },
  rightPart: {
    gridArea: "rightPart",
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "space-between",
    boxSizing: "border-box",
    flexDirection: "column",
    gap: 7,
  },
  logoArea: {
    height: "22%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  logoImage: {
    width: "90%",
    alignItems: "center",
    height: "90%",
    objectFit: "contain",
  },
  commoditieTable: {
    gridArea: "commoditieTable",
    height: "60%",
    display: "flex",
    boxSizing: "border-box",
    flexDirection: "column",
    borderRadius: "5px",
  },
  table: {
    gridArea: "table",
    boxSizing: "border-box",
    color: "#AD1457",
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  tabeHeader: {
    gridArea: "tabeHeader",
    backgroundColor: "#C3343B",
    color: "white",
    flex: "1",
    display: "flex",
    justifyContent: "center",
    borderTopRightRadius: "20px",
    borderTopLeftRadius: "20px",
    alignItems: "center",
    height: "15%",
  },
  dollarBox: {
    padding: "0px 6px 0px 6px",
    fontSize: ".8vw",
    backgroundColor: "#C3343B",
    borderRadius: "5px",
  },
  tableContent: {
    gridArea: "tableContent",
    display: "flex",
    height: "85%",
    flexDirection: "column",
    justifyContent: "space-between",
    overflowX: "scroll",
    overflowX: "hidden",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    border: " 1px solid #AC7F81",
    backgroundColor: "#59171A",
    borderRadius: "0 0 15px 15px",
  },
  tableRowColumn: {
    flex: "1",
    display: "flex",
    alignItems: "center",
    color: "#FFFFFF",
    justifyContent: "center",
    fontSize: "2vw",
  },
  tableRow1: {
    flexBasis: 0,
    minHeight: "19.8%",
    flexGrow: 1,
    display: "flex",
  },
  ratio: {
    height: "15%",
    gridArea: "stat",
    borderRadius: "20px",
    color: "#fff",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#59171A",
    border: " 1px solid #AC7F81",
  },
  bullionStatsImg: {
    width: "10vw",
    height: "auto",
  },
  updates: {
    padding: "0rem 2rem 1.5rem 2rem",
    display: "flex",
    alignItems: "center",
    height: "9%",
    color: "#FFFFFF",
    boxSizing: "border-box",
  },
  updatesHeader: {
    backgroundColor: "#C3343B",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  updatesContent: {
    display: "flex",
    alignItems: "center",
    height: "100%",
    background: "#FFFFFE",
    color: "#200C02",
    flex: 1,
    // minWidth: "calc( 100vw - 450px )",
  },
  blur: {
    filter: "blur(8px)",
  },
}));

const Home = () => {
  const dispatch = useDispatch();
  const { tv } = useSelector((state) => ({ ...state }));
  const classes = useStyles();

  const [banners, setBanners] = useState([]);
  const [news, setNews] = useState([]);
  const [commodities, setCommodities] = useState([]);
  const [isMarketOpen, setIsMarketOpen] = useState(true);
  const [remainingTime, setRemainingTime] = useState(null);
  const [openlimitExpierd, setOpenlimitExpierd] = useState(false);
  const [openSubscription, setOpenSubscription] = useState(false);
  const [openSubscriptionExpierd, setOpenSubscriptionExpierd] = useState(false);

  const [openBlocked, setOpenBlocked] = useState(false);
  const [deviceName, setDeviceName] = useState("");
  const [deviceId, setDeviceId] = useState("");
  const [userIp, setUserIp] = useState("Ip not found");

  const [displayBidOrBuy, setDisplayBidOrBuy] = useState({
    bidOrBuy: "Bid",
    askOrSell: "Ask",
  });
  const [time, setTime] = useState(new Date());
  const [spread, setSpread] = useState({
    goldAskSpread: 0,
    goldBidSpread: 0,
    silverAskSpread: 0,
    silverBidSpread: 0,
    goldLowSpread: 0,
    goldHighSpread: 0,
    silverLowSpread: 0,
    silverHighSpread: 0,
  });

  const [ratio, setRatio] = useState({
    Buyers: "77%",
    chgValue: "+0.370%",
    commodity: "Gold",
  });

  const [gold, setGold] = useState({
    cur: { ask: 0, bid: 0, highPrice: 0, lowPrice: 0 },
    pre: { ask: 0, bid: 0, highPrice: 0, lowPrice: 0 },
  });
  const [silver, setSilver] = useState({
    cur: { ask: 0, bid: 0, highPrice: 0, lowPrice: 0 },
    pre: { ask: 0, bid: 0, highPrice: 0, lowPrice: 0 },
  });
  const [render, setRender] = useState(true);

  useMemo(() => {
    if (socket && render) {
      setRender(false);
      socket.on("connect", () => {});
      socket.on("disconnect", () => {});
      socket.on("connect_error", (err) => {
        console.log(`connect_error due to ${err.message}`);
      });
      socket.on("gold-rate-change", (data) => {
        setIsMarketOpen(data.data.isMarketOpen);
        setGold((e) => {
          return { cur: data.data, pre: e.cur };
        });
      });
      socket.on("silver-rate-change", (data) => {
        setSilver((e) => {
          return { cur: data.data, pre: e.cur };
        });
      });
    }
  }, [socket]);

  useEffect(() => {
    let timerId;
    if (!isMarketOpen) {
      timerId = setInterval(() => {
        let currentTime = new Date();
        let nextMonday = new Date();
        let dubaiTime = moment(Date.now()).tz("Asia/Dubai");
        nextMonday.setFullYear(
          Number(dubaiTime.format("YYYY")),
          Number(dubaiTime.format("MM")) - 1,
          Number(dubaiTime.format("D"))
        );
        currentTime.setFullYear(
          Number(dubaiTime.format("YYYY")),
          Number(dubaiTime.format("MM")) - 1,
          Number(dubaiTime.format("D"))
        );
        currentTime.setHours(
          dubaiTime.format("H"),
          dubaiTime.format("mm"),
          dubaiTime.format("ss")
        );

        if (dubaiTime.format("ddd") !== "Mon") {
          nextMonday.setDate(
            nextMonday.getDate() + ((1 + 7 - nextMonday.getDay()) % 7 || 7)
          );
        }
        nextMonday.setHours(1, 59, 0, 0);
        const elapsedTime = nextMonday - currentTime;
        if (elapsedTime <= 0) {
          clearInterval(timerId);
        } else {
          setRemainingTime(elapsedTime);
        }
      }, 1000);
    }
    return () => clearInterval(timerId); // Clear the timer when the component unmounts or when startTimer becomes false
  }, [isMarketOpen]);

  const formatRemainingTime = (time) => {
    const seconds = Math.floor((time / 1000) % 60);
    const minutes = Math.floor((time / 1000 / 60) % 60);
    const hours = Math.floor((time / 1000 / 60 / 60) % 24);
    const days = Math.floor(time / 1000 / 60 / 60 / 24);

    return `${days}d ${hours}h ${minutes}m ${seconds}s`;
  };

  const commodityCalculation = (
    livePrice,
    spread,
    premium,
    fxRate,
    purity,
    unit,
    weight,
    charge
  ) => {
    let value =
      (Number(livePrice) + Number(spread) + Number(premium)) *
        (fxRate / 31.1035) *
        Number(purity) *
        Number(weight) *
        Number(unit) +
      Number(charge);
    if (value.toFixed(0).length < 5) {
      return value.toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    } else {
      return value.toLocaleString("en-US", {
        // minimumFractionDigits: 2,
        maximumFractionDigits: 0,
      });
    }
  };

  useEffect(() => {
    handelFindBanners();
  }, []);

  useEffect(() => {
    handleFindRatio();
  }, []);

  useEffect(() => {
    hadlefetchNews();
  }, []);

  useEffect(() => {
    handleFindSpread();
  }, []);

  useEffect(() => {
    handleFinsCommodities();
  }, []);

  // useEffect(() => {
  //   handleloginChecking();
  // }, []);

  useEffect(() => {
    handleFindLiveValueTypeForDisplay();
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      setTime(new Date());
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    socket2.on("connect", () => {});
    socket2.on("disconnect", () => {});
    socket2.emit("join_room", { roomId: adminId });
    socket2.on("commodityRerender", (data) => {
      handleFinsCommodities();
    });
    socket2.on("newsRerender", (data) => {
      hadlefetchNews();
    });
    socket2.on("spreadRerender", (data) => {
      handleFindSpread();
    });
    socket2.on("loginChecking", (data) => {
      handleloginChecking();
    });
    socket2.on("mediaRerender", (data) => {
      handelFindBanners();
    });
    socket2.on("adminBlockRerender", (data) => {
      handleLogout();
    });
    socket2.on("socketRerender", (data) => {
      Cookies.remove("socketUrl");
      window.location.reload();
    });
    socket2.on("bidAndAskTitleRerender", (data) => {
      handleFindLiveValueTypeForDisplay();
    });
  }, []);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     triggerExpire();
  //   }, 60 * 60 * 1000);
  //   return () => clearInterval(interval);
  // }, []);

  // useEffect(() => {
  //   handlecheckSubscriptionExpierd();
  // }, []);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     window.location.reload();
  //   }, 20 * 60 * 1000);
  //   return () => clearInterval(interval);
  // }, []);

  useEffect(() => {
    handleFindSocketUrl();
  }, []);

  //////////////////////login//////////////////////////////
  useEffect(() => {
    handleFindUserDatas();
  }, []);

  useEffect(() => {
    if (tv) {
      console.log("587457452");
      handleloginChecking();
    }
  }, []);

  useEffect(() => {
    if (tv) {
      const interval = setInterval(() => {
        triggerExpire();
      }, 60 * 60 * 1000);
      return () => clearInterval(interval);
    }
  }, [tv]);

  useEffect(() => {
    if (tv) {
      handlecheckSubscriptionExpierd();
    }
  }, [tv]);

  const handleOpenBlocked = () => setOpenBlocked(true);
  const handleCloseBlocked = () => {
    window.location.reload();
    // setOpenBlocked(false);
  };

  async function handleFindUserDatas() {
    const deviceip = await findUserIp();
    setUserIp(deviceip.ip);
    firebaseAuth
      .signInAnonymously()
      .then(async (user) => {
        setDeviceId(user?.user?.uid);
      })
      .catch(alert);
    const device = deviceDetect();
    if (device.isMobile) {
      setDeviceName(device.ua);
    } else {
      setDeviceName(device.userAgent);
    }
  }

  useEffect(() => {
    if (deviceName && deviceId && userIp && !tv) {
      handleLogin();
    }
  }, [deviceName, deviceId, userIp]);

  const handleLogin = async () => {
    const res = await tvLoging({
      adminId: adminId,
      deviceName: deviceName,
      password: "123456",
      deviceId: deviceId,
      deviceIp: userIp,
      loginLocation: "Unknown Address",
    });
    console.log("object");
    if (res.status) {
      dispatch({ type: "LOGIN", payload: res });
      localStorage.setItem("tv", JSON.stringify(res));
      localStorage.setItem("deviceId", JSON.stringify(deviceId));
      handleloginChecking();
    } else {
      if (res == "You have blocked..!") {
        handleOpenBlocked();
        // toast.error(res);s
      } else {
        handleLogout();
      }
    }
  };

  const handleloginChecking = async () => {
    console.log("object254");
    let deviceid = "";
    firebaseAuth
      .signInAnonymously()
      .then(async (user) => {
        deviceid = user?.user?.uid;
        const res = await loginChecking(deviceid);
        if (res.status === false && res?.totalLoginCount <= res?.liveCounts) {
          setOpenlimitExpierd(true);
          // handlecheckSubscriptionExpierd();
        } else if (res.status === false) {
          localStorage.removeItem("tv");
          localStorage.removeItem("deviceId");
          localStorage.removeItem("socketUrl");
          // window.location.reload();
          dispatch({
            type: "LOGOUT",
          });

          handleOpenBlocked();
        }
      })
      .catch(alert);
  };

  const handleLogout = async () => {
    const deviceId = localStorage.getItem("deviceId")
      ? JSON.parse(localStorage.getItem("deviceId"))
      : null;
    const res = await logoutTv({
      adminId: adminId,
      deviceId: deviceId,
    });
    if (res) {
      localStorage.removeItem("tv");
      localStorage.removeItem("deviceId");
      localStorage.removeItem("socketUrl");
      dispatch({
        type: "LOGOUT",
      });
      window.location.reload();
    }
  };

  const triggerExpire = () => {
    const givenDate = new Date(tv?.package.expire_date);
    const oneMonthAgo = new Date(givenDate);
    oneMonthAgo.setDate(givenDate.getDate() - 30);
    let a = oneMonthAgo.toISOString();
    let b = new Date().toISOString();

    if (b >= a) {
      const currentDate = new Date();
      const currentHour = currentDate.getHours();
      if (currentHour === 10) {
        handleOpen();
      }
    }
  };

  const handlecheckSubscriptionExpierd = () => {
    const givenDate = new Date(tv?.package.expire_date);
    let a = givenDate.toISOString();
    let b = new Date().toISOString();
    if (a < b) {
      handleOpenSubscriptionExpierd();
    }
  };

  ////////////////////////////////////////////////////////

  const handleFindSocketUrl = async () => {
    const res = await getSocketUrl();
    if (res) {
      socket = io(res?.socketURL);
      Cookies.set("socketUrl", JSON.stringify(res?.socketURL));
    }
  };

  const progress = Number(ratio?.Buyers?.match(/\d+/)[0]);

  const buyersColor = "#2B80BE";
  const sellersColor = "#D1172D";

  const progressStyle = {
    backgroundColor: sellersColor,
  };

  const barStyle = {
    backgroundColor: buyersColor,
  };

  const formatTime = (date) => {
    return date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
  };

  const formatDate = () => {
    return moment(new Date()).format("DD MMM YYYY");
  };

  const formatday = () => {
    return moment(new Date()).format("dddd");
  };

  const handleOpen = () => setOpenSubscription(true);
  const handleClose = () => setOpenSubscription(false);
  const handleOpenSubscriptionExpierd = () => setOpenSubscriptionExpierd(true);
  const handleCloseSubscriptionExpierd = () =>
    setOpenSubscriptionExpierd(false);

  const handelFindBanners = async () => {
    const res = await findBanners();
    if (Array.isArray(res)) {
      setBanners(res);
    }
  };

  const handleFinsCommodities = async () => {
    const res = await allCommodities();
    if (Array.isArray(res.commodities)) {
      setCommodities(res.commodities);
    }
  };

  const handleFindSpread = async () => {
    const res = await getSpread();
    if (res) {
      setSpread(res);
    }
  };

  const handleFindRatio = async () => {
    const res = await getRatio();
    if (res?.data) {
      setRatio(res.data);
    }
  };

  const hadlefetchNews = async () => {
    const res = await allNewses();
    if (Array.isArray(res)) {
      setNews(res);
    }
  };

  const handleFindLiveValueTypeForDisplay = async () => {
    const res = await getLiveValueTypeForDisplay();
    if (res) {
      setDisplayBidOrBuy(res);
    }
  };

  return (
    <Box
      style={{
        backgroundImage: `url(${bgImage})`,
        width: "100dvw",
        height: "100dvh",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }}
      className={(openlimitExpierd || openSubscriptionExpierd) && classes.blur}
    >
      <Box className={classes.mainBody}>
        <Box className={classes.mainContainer}>
          {!isMarketOpen && (
            <Box
              sx={{
                position: "fixed",
                top: "0",
                left: "-125px",
                right: "0",
                bottom: "0",
                width: "895px",
                height: "531px",
                overflow: "hidden",
                zIndex: "99",
              }}
            >
              <Box
                style={{
                  position: "relative",
                  width: "100%",
                  height: "10%",
                  transform: "rotate(-35deg)",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "#561A1D",
                  color: "#FFFFFF",
                  fontSize: "2vw",
                  fontWeight: "bold",
                  padding: "20px",
                  lineHeight: 1.5,
                  alignItems: "center",
                  flexDirection: "column",
                  justifyContent: "space-evenly",
                }}
              >
                <Marquee delay="2" gradient={false}>
                  {remainingTime && (
                    <p style={{ marginLeft: "90px" }}>
                      Market closed! Opens on{" "}
                      {formatRemainingTime(remainingTime)}
                    </p>
                  )}
                </Marquee>
              </Box>
            </Box>
          )}
          <Box className={classes.leftPart}>
            <Box className={classes.timeArea}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                <AccessTimeIcon
                  sx={{
                    fontSize: "3vw",
                    color: "#300703",
                    backgroundColor: "white",
                    borderRadius: "50%",
                  }}
                />
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: "2.4vw",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {formatTime(time)}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                <CalendarMonthIcon
                  sx={{
                    fontSize: "2.7vw",
                    color: "#300703",
                    backgroundColor: "white",
                    borderRadius: "50%",
                    p: 0.5,
                  }}
                />
                <Box>
                  <Typography
                    sx={{
                      fontSize: "1.5vw",
                      letterSpacing: "2.2px",
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-between",
                      fontWeight: "bold",
                    }}
                  >
                    {formatday()
                      .split("")
                      .map((item, index) => (
                        <span key={index}>{item.toUpperCase()}</span>
                      ))}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "1.2vw",
                      mt: -1,
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-between",
                      letterSpacing: "4px",
                      gap: 1,
                    }}
                  >
                    {formatDate()
                      .split(" ")
                      .map((item, index) => (
                        <span key={index}>{item.toUpperCase()}</span>
                      ))}
                  </Typography>
                </Box>
              </Box>
            </Box>

            <Box className={classes.banner}>
              <Carousel
                animation="fade" // Set the animation type to slide
                navButtonsAlwaysVisible={false} // Show navigation buttons always
                interval={10000}
                indicatorContainerProps={{
                  style: {
                    display: "none",
                  },
                }}
                sx={{
                  height: "100%",
                  borderRadius: "2px",
                }}
                transitionTime={3000} // Adjust the transition time to control the speed of the fade effect
                autoPlay
                duration={2000}
              >
                {banners?.map((banner, i) => (
                  <img
                    style={{
                      width: "100%",
                      height: "29.5vh",
                      objectFit: "cover",
                      borderRadius: "20px",
                      boxSizing: "border-box",
                      border: "1px solid #AC7F81",
                    }}
                    src={banner.imageUrl}
                    alt="banner"
                  />
                ))}
              </Carousel>
            </Box>
            <Box className={classes.spotRate}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  height: "20%",
                  borderBottom: "1px solid #AC7F81",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "1.4vw",
                    fontWeight: "bold",
                    flexBasis: 0,
                    flexGrow: 0.8,
                    flexShrink: 1,
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  SPOT RATE
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "5px",
                    flexBasis: 0,
                    flexGrow: 1,
                    flexShrink: 1,
                  }}
                >
                  {" "}
                  <Typography className={classes.dollarBox}>$</Typography>
                  <Typography sx={{ fontSize: "1.4vw", fontWeight: "bold" }}>
                    {displayBidOrBuy?.bidOrBuy?.toUpperCase()}
                  </Typography>
                  <Typography style={{ fontSize: "1.2vw" }}>oz</Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "5px",
                    flexBasis: 0,
                    flexGrow: 1,
                    flexShrink: 1,
                  }}
                >
                  <Typography className={classes.dollarBox}>$</Typography>
                  <Typography sx={{ fontSize: "1.4vw", fontWeight: "bold" }}>
                    {displayBidOrBuy?.askOrSell?.toUpperCase()}
                  </Typography>
                  <Typography style={{ fontSize: "1.2vw" }}>oz</Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  height: "35%",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "1.8vw",
                    fontWeight: "bold",
                    p: 0,
                    flexBasis: 0,
                    flexGrow: 0.8,
                    flexShrink: 1,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    mb: 3,
                  }}
                >
                  GOLD
                </Typography>

                <Box className={classes.spotRateBox}>
                  <Box
                    className={classes.liveRateValue}
                    sx={{
                      backgroundColor:
                        Number(gold?.cur?.bid).toFixed(2) <
                        Number(gold?.pre?.bid).toFixed(2)
                          ? "#D1172D"
                          : Number(gold?.cur?.bid).toFixed(2) >
                            Number(gold?.pre?.bid).toFixed(2)
                          ? "#2B80BE"
                          : "",
                    }}
                  >
                    <Typography sx={{ fontSize: "2vw", fontWeight: "bold" }}>
                      {(
                        Number(spread?.goldBidSpread) + Number(gold?.cur?.bid)
                      ).toFixed(2)}
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      gap: "5px",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      style={{
                        fontSize: ".7vw",
                        background: "#D1172D",
                        borderRadius: "5px",
                        width: "3rem",
                        textAlign: "center",
                        padding: 1,
                      }}
                    >
                      Low
                    </Typography>
                    <Typography style={{ fontSize: "1vw" }}>
                      {(
                        Number(spread.goldLowSpread) +
                        Number(gold?.cur?.lowPrice)
                      ).toFixed(2)}
                    </Typography>
                  </Box>
                </Box>

                <Box className={classes.spotRateBox}>
                  <Box
                    className={classes.liveRateValue}
                    sx={{
                      backgroundColor:
                        Number(gold?.cur?.ask).toFixed(2) <
                        Number(gold?.pre?.ask).toFixed(2)
                          ? "#D1172D"
                          : Number(gold?.cur?.ask).toFixed(2) >
                            Number(gold?.pre?.ask).toFixed(2)
                          ? "#2B80BE"
                          : "",
                    }}
                  >
                    <Typography sx={{ fontSize: "2vw", fontWeight: "bold" }}>
                      {(
                        Number(spread?.goldAskSpread) + Number(gold?.cur?.ask)
                      ).toFixed(2)}
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      gap: "5px",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      style={{
                        fontSize: ".7vw",
                        background: "#3DA1EA",
                        borderRadius: "5px",
                        width: "3rem",
                        textAlign: "center",
                        padding: 1,
                      }}
                    >
                      High
                    </Typography>
                    <Typography style={{ fontSize: "1vw" }}>
                      {(
                        Number(spread?.goldHighSpread) +
                        Number(gold?.cur?.highPrice)
                      ).toFixed(2)}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  height: "35%",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "1.4vw",
                    flexBasis: 0,
                    flexGrow: 0.8,
                    flexShrink: 1,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    mb: 3,
                  }}
                >
                  SILVER
                </Typography>

                <Box className={classes.spotRateBox}>
                  <Box
                    className={classes.liveRateValueSilver}
                    sx={{
                      backgroundColor:
                        Number(silver?.cur?.bid).toFixed(3) <
                        Number(silver?.pre?.bid).toFixed(3)
                          ? "#D1172D"
                          : Number(silver?.cur?.bid).toFixed(3) >
                            Number(silver?.pre?.bid).toFixed(3)
                          ? "#2B80BE"
                          : "",
                    }}
                  >
                    <Typography sx={{ fontSize: "1.8vw" }}>
                      {(
                        Number(spread?.silverBidSpread) +
                        Number(silver?.cur?.bid)
                      ).toFixed(3)}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      gap: "5px",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      style={{
                        fontSize: ".7vw",
                        background: "#D1172D",
                        borderRadius: "5px",
                        width: "3rem",
                        padding: 1,
                        textAlign: "center",
                      }}
                    >
                      Low
                    </Typography>
                    <Typography style={{ fontSize: "1vw" }}>
                      {(
                        Number(spread.silverLowSpread) +
                        Number(silver?.cur?.lowPrice)
                      ).toFixed(3)}
                      &nbsp;
                    </Typography>
                  </Box>
                </Box>

                <Box className={classes.spotRateBox}>
                  <Box
                    className={classes.liveRateValueSilver}
                    sx={{
                      backgroundColor:
                        Number(silver?.cur?.ask).toFixed(3) <
                        Number(silver?.pre?.ask).toFixed(3)
                          ? "#D1172D"
                          : Number(silver?.cur?.ask).toFixed(3) >
                            Number(silver?.pre?.ask).toFixed(3)
                          ? "#2B80BE"
                          : "",
                    }}
                  >
                    <Typography sx={{ fontSize: "1.8vw" }}>
                      {(
                        Number(spread?.silverAskSpread) +
                        Number(silver?.cur?.ask)
                      ).toFixed(3)}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      gap: "5px",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      style={{
                        fontSize: ".7vw",
                        background: "#3DA1EA",
                        borderRadius: "5px",
                        width: "3rem",
                        padding: 1,
                        textAlign: "center",
                      }}
                    >
                      High
                    </Typography>
                    <Typography style={{ fontSize: "1vw" }}>
                      {(
                        Number(spread?.silverHighSpread) +
                        Number(silver?.cur?.highPrice)
                      ).toFixed(3)}
                      &nbsp;
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box className={classes.rightPart}>
            <Box className={classes.logoArea}>
              <Box sx={{ width: "50%", height: "100%" }}>
                <img
                  className={classes.logoImage}
                  src={CeekayLogo}
                  alt="leenLogo"
                />
              </Box>
            </Box>

            <Box className={classes.commoditieTable}>
              <Box className={classes.table}>
                <Box className={classes.tabeHeader}>
                  <Typography
                    sx={{
                      flex: 1,
                      display: "flex",
                      justifyContent: "center",
                      fontSize: "2vw",
                      fontWeight: "bold",
                    }}
                  >
                    COMMODITY
                  </Typography>
                  <Typography
                    sx={{
                      flex: 1,
                      display: "flex",
                      justifyContent: "center",
                      fontSize: "2vw",
                      fontWeight: "bold",
                    }}
                  >
                    WEIGHT
                  </Typography>
                  <Typography
                    sx={{
                      flex: 1,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontSize: "2vw",
                      fontWeight: "bold",
                    }}
                  >
                    BUY
                    <Typography sx={{ fontSize: "0.9vw", mt: 2.2 }}>
                      &nbsp;AED
                    </Typography>
                  </Typography>
                  <Typography
                    sx={{
                      flex: 1,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontSize: "2vw",
                      fontWeight: "bold",
                    }}
                  >
                    SELL
                    <Typography sx={{ fontSize: "0.9vw", mt: 2.2 }}>
                      &nbsp;AED
                    </Typography>
                  </Typography>
                </Box>

                <Box className={classes.tableContent}>
                  {commodities?.map((commodity, idx) => {
                    const words = commodity.commodity_title.split(" ");
                    return (
                      <>
                        <Box
                          key={idx}
                          className={classes.tableRow1}
                          sx={{
                            maxHeight: commodities.length > 6 ? "17%" : "",
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "1.3vw",
                              fontWeight: "bold",
                              justifyContent: "left",
                            }}
                            className={classes.tableRowColumn}
                          >
                            {words?.map((word, index) => {
                              if (index === 0) {
                                return (
                                  <span
                                    key={index}
                                    style={{
                                      fontSize: "2vw",
                                      fontWeight: "bold",
                                      paddingLeft: "30px",
                                    }}
                                  >
                                    {word.toUpperCase()}
                                  </span>
                                );
                              } else {
                                return (
                                  <span
                                    key={index}
                                    style={{
                                      fontSize: "1vw",
                                      marginTop: "0.2rem",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    &nbsp;{word.toUpperCase()}
                                  </span>
                                );
                              }
                            })}
                            &nbsp;
                            {commodity.unitLabel === "TTB"
                              ? ""
                              : commodity.displayPurity}
                          </Typography>
                          <Box
                            className={classes.tableRowColumn}
                            sx={{
                              justifyContent: "left",
                            }}
                          >
                            <Typography
                              style={{ fontSize: "2vw", fontWeight: "bold" }}
                              sx={{
                                paddingLeft: { lg: "4.8vw", md: "4.2vw" },
                              }}
                            >
                              {commodity.unit} {commodity.unitLabel}
                            </Typography>
                          </Box>
                          <Box
                            className={classes.tableRowColumn}
                            sx={{
                              justifyContent: "left",
                            }}
                          >
                            <Typography
                              style={{
                                fontSize: "2vw",
                                fontWeight: "bold",
                              }}
                              sx={{
                                paddingLeft: { lg: "4.4vw", md: "4vw" },
                              }}
                            >
                              {commodityCalculation(
                                commodity.commodity_title === "Silver"
                                  ? silver?.cur?.bid
                                  : gold?.cur?.bid,
                                commodity.commodity_title === "Silver"
                                  ? spread?.silverBidSpread
                                  : spread?.goldBidSpread,
                                commodity?.buy_premium,
                                3.674,
                                commodity.purity,
                                commodity.unit,
                                commodity.unitLabel === "TTB"
                                  ? 116.64
                                  : commodity.unitLabel === "KG"
                                  ? 1000
                                  : commodity.unitLabel === "OZ"
                                  ? 31.1
                                  : commodity.unitLabel === "TOLA"
                                  ? 11.7
                                  : 1,
                                commodity.buy_charge
                              )}
                            </Typography>
                          </Box>
                          <Box
                            className={classes.tableRowColumn}
                            sx={{
                              justifyContent: "left",
                            }}
                          >
                            <Typography
                              style={{ fontSize: "2vw", fontWeight: "bold" }}
                              sx={{
                                paddingLeft: { lg: "3.8vw", md: "3.5vw" },
                              }}
                            >
                              {commodityCalculation(
                                commodity.commodity_title === "Silver"
                                  ? silver?.cur?.ask
                                  : gold?.cur?.ask,
                                commodity.commodity_title === "Silver"
                                  ? spread.silverAskSpread
                                  : spread.goldAskSpread,
                                commodity.premium,
                                3.674,
                                commodity.purity,
                                commodity.unit,
                                commodity.unitLabel === "TTB"
                                  ? 116.64
                                  : commodity.unitLabel === "KG"
                                  ? 1000
                                  : commodity.unitLabel === "OZ"
                                  ? 31.1
                                  : commodity.unitLabel === "TOLA"
                                  ? 11.7
                                  : 1,
                                commodity.charges
                              )}
                            </Typography>
                          </Box>
                        </Box>
                        <Divider sx={{ bgcolor: "#AC7F81", mx: 2 }} />
                      </>
                    );
                  })}
                </Box>
              </Box>
            </Box>
            <Box className={classes.ratio}>
              <Box
                sx={{
                  width: "80%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    px: 7,
                    py: 1,
                    boxSizing: "border-box",
                  }}
                >
                  <Typography sx={{ fontSize: ".9vw" }}>BUYERS</Typography>
                  <Typography
                    sx={{
                      color: "#F8FBFF",
                      fontSize: ".9vw",
                      color:
                        Number(ratio?.chgValue.replace("%", "")) >= 0
                          ? "#2B80BE"
                          : "#D1172D",
                    }}
                  >
                    {ratio?.chgValue}
                  </Typography>
                  <Typography sx={{ fontSize: ".9vw" }}>SELLERS</Typography>
                </Box>
                <Box sx={{ width: "90%" }}>
                  <LinearProgress
                    variant="determinate"
                    value={progress}
                    style={progressStyle}
                    sx={{
                      "& .MuiLinearProgress-bar": barStyle,
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    px: 7,
                    py: 1,
                    boxSizing: "border-box",
                  }}
                >
                  <Typography sx={{ fontSize: ".9vw", color: "#2B80BE" }}>
                    {ratio?.Buyers}
                  </Typography>
                  <Box sx={{ mt: 1 }}>
                    <a href="https://www.bullionstats.com/">
                      <img
                        className={classes.bullionStatsImg}
                        src={bullionStats}
                        alt="bullionStats"
                      />
                    </a>
                  </Box>

                  <Typography sx={{ fontSize: ".9vw", color: "#D1172D" }}>
                    {100 - Number(ratio?.Buyers?.match(/\d+/)[0])}%
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className={classes.updates}>
          <Box
            className={classes.updatesHeader}
            sx={{
              minWidth: { lg: "180px", md: "135px" },
            }}
          >
            <Typography
              sx={{
                fontSize: ".9vw",
                textAlign: "center",
                letterSpacing: "2px",
              }}
            >
              CEEKAY
              <br />
              <Box
                style={{
                  fontWeight: "bold",
                  marginTop: "-7px",
                  fontSize: "1.1vw",
                  letterSpacing: "5px",
                }}
              >
                NEWS
              </Box>
            </Typography>
          </Box>
          <Box className={classes.updatesContent}>
            <marquee behavior="" direction="">
              <Box sx={{ display: "flex" }}>
                {news?.map((item, index) => {
                  return (
                    <Box
                      key={item._id}
                      sx={{
                        fontSize: "1.6vw",
                        fontFamily: "poppins",
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      <span style={{ fontWeight: "bold" }}>
                        {item.newsTitle}
                      </span>
                      &nbsp;&nbsp;:&nbsp;&nbsp;<span>{item.newsDetails}</span>
                      <span
                        style={{ paddingRight: "50px", paddingLeft: "50px" }}
                      >
                        {index === news.length - 1 ? "" : "|"}
                      </span>
                    </Box>
                  );
                })}
              </Box>
            </marquee>
          </Box>
          <Box
            className={classes.updatesHeader}
            sx={{ backgroundColor: "#C3343B", fontSize: ".8vw", px: 2 }}
          >
            www.ceekaygoldanddiamonds.com
          </Box>
        </Box>
      </Box>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openlimitExpierd}
        closeAfterTransition
      >
        <Box>
          <LimitExceededModal logout={handleLogout} />
        </Box>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openSubscription}
        // onClose={handleClose}
        closeAfterTransition
      >
        <Box>
          <SubscriptionExpiringSoon
            date={tv?.package.expire_date}
            handleClose={handleClose}
          />
        </Box>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openSubscriptionExpierd}
        // onClose={handleCloseSubscriptionExpierd}
        closeAfterTransition
      >
        <Box>
          <SubscriptionExpired handleClose={handleLogout} />
        </Box>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openBlocked}
        // onClose={handleCloseBlocked}
        closeAfterTransition
      >
        <Box>
          <BlockModal handleClose={handleCloseBlocked} />
        </Box>
      </Modal>
    </Box>
  );
};

export default Home;
