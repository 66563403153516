import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import { Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import LoggedIn from "./routes/LoggedIn";
import NotLoggedIn from "./routes/NotLoggedIn";
import Login from "./page/Login";
import Home from "./page/Home";
import ErrorPage from "./page/Errorpage";
import { useMediaQuery } from "@mui/material";
import MobileErrorPage from "./page/MobileErrorPage";
import { Offline, Online } from "react-detect-offline";
import NoInternet from "./page/NoInternet";

function App() {
  const isTvScreen = useMediaQuery("(min-width: 1000px)");
  return (
    <div>
      <Online>
        <ToastContainer />
        {isTvScreen ? (
          <Routes>
            {/* <Route element={<LoggedIn />}> */}
            <Route path="/" exact element={<Home />} />
            {/* </Route> */}
            {/* <Route element={<NotLoggedIn />}>
              <Route path="/login" element={<Login />} />
            </Route> */}
            <Route path="*" element={<ErrorPage />} />
          </Routes>
        ) : (
          <MobileErrorPage />
        )}
      </Online>
      <Offline>
        <NoInternet />
      </Offline>
    </div>
  );
}

export default App;
